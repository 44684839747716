<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Pre-autorización</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="facturas_filtradas" :select="seleccionar" @actions="dataGridActions" @ready="dataGridReady">
			<div class="col-md-2 btn-recargar">
				<button class="btn warning-btn" @click="obtener_facturas">Recargar</button>
			</div>
			<div v-if="$auth.can('tesoreria', 'actualizar_revision_factura_preautorizador')" class="col-md-2 btn-revisar">
				<button class="btn complementary-btn" @click="revisar">Revisar</button>
			</div>
			<div class="col-md-2 btn-ayuda">
				<div class="dropdown v-step-bt-4">
				  <button class="btn complementary-principal-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Ayuda
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'interface')">Interface</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'detalles')">Ver detalles de facturas</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'filtros')">Filtros</a>
				  </div>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="ver_detalles" :options="{width: '80vw',close:true}" @close="cancelar_revisar_factura" class="bpb-modal">
			<h2 class="title">Factura</h2>
			<div class="body">
				<FacturaView :factura="factura" @observaciones="agregar_observacion"/>
				
			</div>
			<div v-if="factura.estatus == 2" class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn" @click="actualizar_factura(1)">Validada</button>
						</div>
						<div class="col-md-3">
							<button class="btn warning-btn" @click="actualizar_factura(2)">Pendiente</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn" @click="cancelar_revisar_factura">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<v-tour name="tour-interface" :steps="steps_inteface" :options="steps_options"></v-tour>
		<v-tour name="tour-detalles" :steps="filtros_detalles_steps" :options="steps_options" :callbacks="steps_callbacks_detalles"></v-tour>
		<v-tour name="tour-filtros" :steps="steps_filtros" :options="steps_options" :callbacks="steps_callbacks_filtros"></v-tour>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import FacturaView from '@/apps/tesoreria/components/FacturasView'

	import tesoreria from '@/apps/tesoreria/api/tesoreria'

	export default {
		components: {
			DataGrid, Modal, FacturaView
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					empresa_id: 'Proveedor'
					,proveedor_nombre: 'Representante'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,created_at: 'Fecha de subida'
					,metodo_pago: 'Método Pago'
					,estatus: 'Estatus'
					,id: 'Documentos'
				}
				,filters: {
					cols: {
						id: 'ID'
						,empresa_id: {
							text: 'Proveedor'
							,options: {}
						}
						,proveedor_id: 'Representante'
						,rfc_emisor: 'RFC Emisor'
						,rfc_receptor: 'RFC Receptor'
						,subtotal: 'Subtotal'
						,total: 'Total'
						,estatus: {
							text: 'Estatus'
							,options: {
								1: 'Activo'
								,0: 'Inactivo'
							}
						}
						,created_at: 'Fecha creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					empresa_id: function(val,row) {
						return row.empresa ? row.empresa.empresa : 'Sin especificar';
					}
					,proveedor_id: function(val,row) {
						return row.proveedor.nombre;
					}
					,total: function(val) {
						return '$'+val;
					}
					,subtotal: function(val) {
						return '$'+val;
					}
					,estatus: function(val, row, dataGrid, vuex) {
						let buscar_estatus = function(vuex, estatus) {
							let nombre = '';
							// console.log('vuex', vuex);
							if (vuex.factura_status)
								for(let i=0; i<vuex.factura_status.length; i++) {
									if (vuex.factura_status[i].llave == 'catalogo_estatus' && parseInt(vuex.factura_status[i].valor) == estatus) {
										nombre = vuex.factura_status[i].nombre;
										i=vuex.factura_status.length;
									}
								}

							return nombre;
						}

						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">'+buscar_estatus(vuex, 0)+'</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">'+buscar_estatus(vuex, 1)+'</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">'+buscar_estatus(vuex, 2)+'</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">'+buscar_estatus(vuex, 3)+'</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 4)+'</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 5)+'</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 6)+'</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row) {
						return '<button class="btn principal-btn" name="descargar_documentos" data-factura="'+encodeURI(JSON.stringify(row))+'">Descargar</button>';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
			,factura: {}
			,seleccionados: []
			,seleccionar: false
			,ver_detalles: false
			,observacion: null
			,rechazar: false
			,subirFactura: false
			,comentarios: null
			,steps_options: {
				labels: {
					buttonSkip: 'Finalizar'
					,buttonPrevious: 'Anterior'
					,buttonNext: 'Siguiente'
					,buttonStop: 'Finalizar'
				}
			}
			,step_inteface: 0
			,steps_inteface: [
				{
					target: '.btn-recargar'
					,content: 'Permite recargar el contenido manteniendo la configuración actual (filtros, paginado, orden, etc)'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: '.btn-ayuda'
					,content: 'Muestra información del dashboard y lista las guías de la aplicación'
				}
				,{
					target: 'table.table-striped'
					,content: 'Grid donde se muestra y ordena la información'
				}
				,{
					target: 'button[name="descargar_documentos"]'
					,content: 'Permite descargar los documentos de la factura indicada'
				}
			]
			,steps_callbacks_detalles: {}
			,step_detalles: 0
			,filtros_detalles_steps: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Seleccionamos una factura'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: 'label[for="proveedor"]'
					,content: 'Se visualiza información más detallada de la factura'
				}
				,{
					target: 'label[for="conceptos"]'
					,content: 'Listamos todos los conceptos de la factura'
				}
				,{
					target: 'label[for="impuestos"]'
					,content: 'Listamos todos los impuestos de la factura'
				}
				,{
					target: 'label[for="pagos"]'
					,content: 'Cuando la factura ya tiene pagos se listarán aquí'
				}
				,{
					target: 'label[for="observaciones"]'
					,content: 'Se listan las observaciones que se van agregando según el proceso que lleve la factura, también podrás ver observaciones que agreguen los operadores de BienParaBien'
				}
				,{
					target: '.agregar_observacion_btn'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
			]
			,steps_callbacks_filtros: {}
			,step_filtros: 0
			,steps_filtros: [
				{
					target: '#dataGrid-btn-add-filter'
					,content: 'Los filtros funcionan para limitar los registros a visualizar, especialmente cuando se busca un registro o registros en particular, para agregar un filtro comenzamos dando click al botón "Agregar filtro"'
				}
				,{
					target: '#filtro_columna'
					,content: 'Seleccionamos la columna a la que se le aplicará el filtro'
				}
				,{
					target: '#filtro_operador'
					,content: 'Seleccionamos el operador que se aplicará al filtro'
				}
				,{
					target: '#valor1'
					,content: 'Indicamos el valor que tendrá el filtro, esto podría ser unicamente texto o en caso de que la columna sea de un tipo en especifico deberá tener un formato en especial, por ejemplo, para fechas el formato tendrá que ser YYYY-MM-DD HH:ii:ss, puede ser solo la fecha, la fecha y hora o solo la hora, algunos operadores pueden requerir más de un dato'
				}
				,{
					target: '.dataGrid-filter-btn-add'
					,content: 'Finalmente damos click en el botón agregar o cancelar en caso que ya no desees agregar filtros'
				}
			]
		})
		,created: function() {
			// this.obtener_facturas();
			this.cargar_estatus();
			this.cargar_empresas();

			this.steps_callbacks_detalles = {
				onStart: this.tour_detalles_next
				,onNextStep: this.tour_detalles_next
				,onPreviousStep: this.tour_detalles_prev
				,onStop: this.tour_detalles_stop
			}

			this.steps_callbacks_filtros = {
				onNextStep: this.tour_filtros
				,onPreviousStep: this.tour_filtros_prev
				,onStop: this.tour_filtros_stop
			}
		}
		,methods: {
			obtener_facturas: function() {
				tesoreria.obtener_preautorizadores_facturas(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err, 'Error');
				});
			}
			,cargar_estatus: function() {
				if (!this.$store.state.factura_status)
					tesoreria.cargar_estatus()
					.then(res => {
						this.$log.info('res', res);
						this.$store.commit('set', {factura_status: res.data});
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
			}
			,cargar_empresas: async function() {
				let empresas = (await tesoreria.listar_empresas()).data;

				empresas.some((empresa) => {
					this.dataGridConfig.filters.cols.empresa_id.options[empresa.id] = empresa.empresa;
				});
			}
			,dataGridActions: function(type, data) {
				if (type == 'options') {
					this.options = data;
					this.obtener_facturas();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,dataGridReady: function() {
				// this.$log.info('Ready!');
				document.querySelectorAll('button[name=descargar_documentos]').forEach(btn => {
					// this.$log.info('btn', btn);
					btn.removeEventListener('click', this.descargar_documentos);
					btn.addEventListener('click', this.descargar_documentos);
				})
			}
			,descargar_documentos: async function(e) {
				let factura = JSON.parse(decodeURI(e.target.getAttribute('data-factura')));
				try {
					let res = (await tesoreria.descargar_documentos_v2(factura.id)).data;
					let domain = process.env.VUE_APP_API_TESORERIA;
          domain = domain.replace('api/v1','storage');
          window.open(domain+'/'+res.file);
				}catch (e){
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,revisar: function() {
				if (this.seleccionados.length > 0) {
					this.factura = this.seleccionados[0];
					this.ver_detalles = true;
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error'
						,text: 'Debes seleccionar una factura'
						,duration: 5000
					});
				}
			}
			,agregar_observacion: function(observacion) {
				if (observacion && observacion.length > 5) {
					tesoreria.enviar_observaciones_factura(this.factura.id, {observacion: observacion})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones = res.data;
						this.observacion = null;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,actualizar_factura: async function(estatus) {
				try {
					await tesoreria.actualizar_revision_factura(this.factura.id, estatus);

					this.cancelar_revisar_factura();
					this.obtener_facturas();
				}catch(err) {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				}
			}
			,cancelar_revisar_factura: function() {
				this.ver_detalles = false;
				this.factura = {}
				this.seleccionar = false;
			}
			,iniciar_tour: function(e, tipo) {
				e.preventDefault();

				switch(tipo) {
					case 'interface':
						this.$tours['tour-interface'].start();
						break;
					case 'detalles':
						this.$tours['tour-detalles'].start();
						break;
					case 'filtros':
						this.$tours['tour-filtros'].start();
						break;
				}
			}
			,tour_filtros: function(paso) {
				this.$log.info('step filtros', paso);
				this.step_filtros = paso;

				if (paso == 0)
					document.getElementById('dataGrid-btn-add-filter').click();

				if (paso >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(paso) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_prev: function() {
				this.step_filtros -= 1;
				this.$log.info('step filtros prev', this.step_filtros);

				if (this.step_filtros >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(this.step_filtros) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_stop: function() {
				document.querySelector('button.dataGrid-filter-btn-cancel').click();
				this.step_filtros = 0;
			}
			,tour_detalles_next: function(step) {
				this.$log.info('step',step)
				this.step_detalles = step;

				if (typeof(step) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();

				if (step == 1)
					this.revisar();
			}
			,tour_detalles_prev: function(step) {
				this.step_detalles -= 1;
				this.$log.info('step',this.step_detalles)

				if (this.step_detalles == 0) {
					this.cancelar_revisar_factura();
					setTimeout(() => {
						document.querySelector('input[type="checkbox"][name="facturas"]').click();
					},20);
				}
			}
			,tour_detalles_stop: function() {
				this.cancelar_revisar_factura();
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.factura.total
			}
			,subtotal_procesada: function() {
				return '$'+this.factura.subtotal
			}
			,facturas_filtradas: function() {
				let idAutorizadores = [];
				let facturas = [];

				for(let i=0; i<this.facturas.length; i++) {
          let tiene_autorizadores = (this.facturas[i].proveedor && this.facturas[i].proveedor.autorizadores && this.facturas[i].proveedor.autorizadores[0]);

          if (tiene_autorizadores && idAutorizadores.indexOf(this.facturas[i].proveedor.autorizadores[0].id) == -1)
						idAutorizadores.push(this.facturas[i].proveedor.autorizadores[0].id);
				}

				this.$log.info('idAutorizadores', idAutorizadores);

				for(let i=0; i<this.facturas.length; i++) {
					if (!this.facturas[i].autorizador_id || idAutorizadores.indexOf(this.facturas[i].autorizador_id) != -1)
						facturas.push(this.facturas[i]);
				}

				return this.facturas;
			}
		}
	}
</script>

<style lang="scss">
	.observaciones {
		max-height: 200px;
		overflow: auto;
		
		.row {
			margin-right: 3px;
			padding: 3px;

			span.nombre {
				font-weight: bold;
			}

			span.fecha {
				font-size: 0.8em;
			}
		}

		.row:nth-child(2n+0) {
			background-color: #E6E6E6;
		}
	}

	.razon_rechazo {
		width: 100%;
		height: 170px !important;
		resize: none;
	}
</style>